import React from "react";
import './InfoRow.scss';

export default function InfoRow({ label, skipGreyFilter, className, children }) {

  return <div className={ [
    'info-row is-clearfix',
    !!skipGreyFilter ? '' : 'use-grey-filter',
    className || ''].join(' ').trim() }
  >
    <label>{ label }</label>
    <div className="info-row-content">{ children }</div>
  </div>;
}


/**
 * Used inside InfoRow, for left-column icon
 * note: grey filter handled in InfoRow.jsx, so color displayed when hovering anywhere on row
 * @param src
 * @param className
 * @returns {*}
 * @constructor
 */
export function IconForInfoRow({ src, alt, className }) {
  // <label>{ label }</label>
  return <figure className={ [ 'info-row-icon-holder', className || ''].join(' ').trim() } >
    <img src={src} alt={ alt } />
  </figure>;

}


