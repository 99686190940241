import React from "react"
import {TwoColumnPageSection} from "../../components/PageSection";
import Card from "../../components/Card";
import InfoRow, {IconForInfoRow} from "../../components/InfoRow";
import BookmarksIcon from '../../images-inline/misc/bookmarks.icomoon-modified.component.svg';


export default function ConsultingSection({ sectionNdx }) {
  // leap-motion.png
  return <TwoColumnPageSection
    title="consulting"
    className="consulting-section"
    sectionNdx={sectionNdx}
  >
    <Card>
      <p className="subtitle">Between times of funding for my startups, I've done consulting work:</p>
      <InfoRow
        label={ <IconForInfoRow src="/images/logos/Leap_Motion_logo-light.svg" alt="Leap Motion" /> }
      >
        Interviewed and selected Leap Motion's initial programmers;
        <br/>
        Coded on urgent and at-risk projects in the company's early years (NW.js desktop app and Rails back-end projects)
      </InfoRow>
      <InfoRow
        label={ <IconForInfoRow src="/images/logos/abbott-small.png" alt="Abbott Laboratories" /> }
      >
        Provided engineering management for multiple Abbott Laboratories intranet web projects.
      </InfoRow>
      <InfoRow
        label={ <figure className="inline-svg-holder"><BookmarksIcon /></figure> }
      >
        Completed engineering management, coding, and product planning projects for a variety of software companies.
      </InfoRow>
    </Card>
  </TwoColumnPageSection>;
}
