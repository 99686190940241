import React from "react"
import {FullPageSection} from "../../components/PageSection";
import Card from "../../components/Card";
import InfoRow from "../../components/InfoRow";
import plurchaseLogo from "../../images-inline/logos/plurchase-logo-small.png";
import AddPersonIcon from "../../images-inline/misc/user-plus.icomoon-modified.component.svg";
import ToolboxIcon from "../../images-inline/misc/toolbox.component.svg";
import {BadgeText, BizBadge} from "./badges";
import ycLogo from '../../images-inline/logos/y-combinator.svg';
import './FounderSection.scss';


export default function FounderSection({ sectionNdx }) {
  // todo: badges: Ruby on Rails; Enterprise;  (eg, <TechBadge text={ BadgeText.ExpertJs } />)

  // { <img src={ wranggleLogoWide } alt="Wranggle Logo" /> }
  return <FullPageSection
    title="founder"
    className="founder-section"
    sectionNdx={sectionNdx}
  >
    <div className="columns">
      <div className="column">
        <Card
          className="proxv-founder-section"
          title="ProxV, Inc."
        >
          <div className="details-area">
            { _renderWhatWeOrder() }
          </div>
        </Card>
      </div>

      <div className="column">
        <Card
          className="plurchase-founder column"
          title="Plurchase, Inc."
        >
          <div className="details-area">
            { _renderPlurchase() }
          </div>
        </Card>
      </div>
    </div>
  </FullPageSection>;
}



function _renderWhatWeOrder() {

  return <>
    <InfoRow
      className="overview-row"
      label={<img src="/images/what-we-order-short.png" alt="WhatWeOrder Logo"/>}
    >
      Built and released <i>WhatWeOrder</i>, a modern purchasing/requisition system, processing over $100k/month during its beta.
    </InfoRow>
    <InfoRow
      className="hiring-row"
      label={<figure className="inline-svg-holder add-person-icon-holder">{<AddPersonIcon />}</figure> }
    >
      <div className="badge-holder"><BizBadge text={BadgeText.Recruiting}/></div>
      Hires started as gems-in-the-rough, people almost completely ignored by recruiters, and are now at prestigious
      companies like Dropbox, Tinder, and Twitter.
    </InfoRow>
    <InfoRow
      className="tech-row"
      label={ _toolbox() }
    >
      <p>Wrote the Ruby on Rails backend: customizable forms, workflows, permission systems, reports, ERP/accounting integrations, others.</p>
      <p>Managed and contributed to marketing, sales, etc.</p>
    </InfoRow>
  </>
}


function _renderPlurchase() {
  return <>
    <InfoRow
      className="overview-row"
      label={ <img src={ plurchaseLogo } alt="Plurchase Logo" />}
    >
      Built and released <i>Plurchase</i>, a co-browsing startup for housing search and shopping.
    </InfoRow>
    <InfoRow
      label={ <img src={ ycLogo } alt="YC Logo" />}
    >
      Funded by Y Combinator in 2009, Plurchase was considered one of the more technically impressive by an already technically elite group.
    </InfoRow>
    <InfoRow
      className="tech-row"
      label={ _toolbox() }
    >
      Java, Ruby on Rails, JavaScript; heavy on concurrency and server-push/comet (before good libs existed); UI challenges with little precedent to follow for inspiration.
    </InfoRow>
  </>
}

function _toolbox() {
  return <figure className="is-48x48 inline-svg-holder"><ToolboxIcon className="toolbox-icon" /></figure>;
}
