import React from "react"
import './TopHeader.scss';


export default () => {
  return <section className="top-header-section section solid-backing">
    <div className="columns">
      <div className="column about-column has-text-centered">
        <h1 className="title">
          Jeff Ferber
        </h1>
        <p className="subtitle">
          Over 20 years in software: <br />
          <strong>programming + biz/management</strong>
        </p>
      </div>
      <div className="column has-text-centered">
        <figure className="profile-pic-holder image">
          <img className="is-rounded" src="/images/profile-jeff-ferber-256.jpg" alt="profile pic"/>
        </figure>
      </div>
    </div>
  </section>;
};
