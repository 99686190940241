import React from "react";
import './Card.scss';


function Card({ className, title, children, footer }) {

  return <div className={ [ 'card', className || ''].join(' ').trim() } >
    <div className="card-content">
      {title &&
      <p className="title">
        { title }
      </p> }
      { children }
      { footer }
    </div>
  </div>;

}

export function MiniCard({ title, iconSrc, iconAlt, className, skipGreyFilter, children }) {
  return <Card className="mini-card">
    <div className="tag is-small is-success">{title}</div>
    <div className={ [ skipGreyFilter ? '' : 'use-grey-filter', 'mini-card-content' ].join(' ').trim() }>
      { iconSrc &&
      <figure className="image is-32x32">
        <img src={iconSrc} alt={iconAlt}/>
      </figure>
      }
      { children }
    </div>
  </Card>
}


export default Card;