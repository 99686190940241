import React from "react";
import Page from "../components/Page";
import IntroSection from "../pages-support/bio/IntroSection";
import TopHeader from "../pages-support/bio/TopHeader";
import CurrentWorkSection from "../pages-support/bio/CurrentWorkSection";
import CodeSampleSection from '../pages-support/bio/CodeSampleSection';
import FounderSection from "../pages-support/bio/FounderSection";
import ConsultingSection from "../pages-support/bio/ConsultingSection";
import EarlyCareerSection from "../pages-support/bio/EarlyCareerSection";
import ManagerSection from '../pages-support/bio/ManagerSection';
import ContactSection from '../pages-support/bio/ContactSection';

import './bio.scss';
              

export default () => {
  const sections = [
    IntroSection,
    CurrentWorkSection,
    FounderSection,
    ManagerSection,
    CodeSampleSection,
    ConsultingSection,
    EarlyCareerSection,
    ContactSection,
  ].map((TagName, sectionNdx) => <TagName sectionNdx={sectionNdx} key={sectionNdx} />);
  
  return <Page pageName="bio">
    <TopHeader />
    { sections }
  </Page>
}



/*
3. founder
  pitch: need a new procurement/requisition system,
      <IntroSection/>
    <CurrentWorkSection/>
    <CodeSampleSection/>


    <PurchasingSystemSection />
    <ConsultingSection />
    <AppleSection />
    <EarlyCareerSection />
 */