import React from "react"
import {TwoColumnPageSection} from "../../components/PageSection";
import Card from "../../components/Card";
import CodeHighlight from "../../components/CodeHighlight";
import './CurrentWorkSection.scss';


export default function CurrentWorkSection({ sectionNdx }) {
  // note: may use video auto-start link, eg: https://wranggle.com/?video=2
  return <TwoColumnPageSection
    title="current"
    className="current-work-section"
    sectionNdx={sectionNdx}
  >
    <Card
      title="Wranggle Studio"
    >
      <p className="has-text-info">As time permits, I'm building an app that lets non-programmers automate the browser:</p>

      <CodeHighlight code={ JSON.stringify({
        overview: 'Tool to create partial, human-attended browser automations',
        description: `One person creates a Robotic Process Automation (RPA) script and other people in their department use it to zip through repetitive tasks`,
        tech: 'Electron; browser extension APIs; TypeScript & JavaScript; Node.js; React; Blueprint.js; many others'
      }, null, 2) } language="json"
      />
      <a className="button is-primary is-outlined is-fullwidth" href="https://wranggle.com/">
        <span className="is-hidden-print">Watch Wranggle Overview Videos</span>
        <span className="is-hidden is-print-visible">See videos on wranggle.com</span>
      </a>

    </Card>

  </TwoColumnPageSection>;
}
