import React from "react"
import {MiniCard} from "../../components/Card";
import {TwoColumnPageSection} from "../../components/PageSection";
import './ManagerSection.scss';

export default function ManagerSection({ sectionNdx }) {
  return <TwoColumnPageSection
    title="manager"
    className="manager-section container"
    sectionNdx={sectionNdx}
    titleColContent={ _renderDeepThoughts() }
  >
    <div className="mini-cards columns">
      <ManagerMiniCard title="Experienced">
        Most of my management experience has been with small product teams, usually 6 or fewer people.
        I've done so at my own startups and frequently in consulting engagements, working with teams of various experience levels,
        with on-site and virtual/remote teams, and with off-shore contractors.
      </ManagerMiniCard>

      <ManagerMiniCard title="Hands-on">
        I'm drawn to the high impact of management and its coaching aspects, but I also love building things hands-on and
        worry about losing coding skills that go unused for too long.
        My sweet spot is something that combines engineering and management: as team lead or as time-partitioned engineering manager.
      </ManagerMiniCard>
    </div>
  </TwoColumnPageSection>;
}

function ManagerMiniCard({ title, className, isFullWidth, children }) {
  // is-half-desktop is-one-third-widescreen
  return <div className={ [ className || '', 'column',
    isFullWidth ? 'is-full' : 'is-half is-full-mobile' ].join(' ').trim() }>
    <MiniCard title={ title }>
      { children }
    </MiniCard>
  </div>;
}

function _renderDeepThoughts() {
  return <div className="burn-bg deep-thoughts">
    <details>
      <summary>Deep thoughts on hiring</summary>
      People tend to look for the qualities they value in themselves when hiring. Sometimes that works but it can also lead to unfortunate misses,
      and can easily fail when hiring for other functional roles. I try to fight this "hire myself" instinct by attempting to mentally
      match the candidate with competent people I've worked with in the past instead, even ones very different from me.
    </details>

    <details>
      <summary>Deep thoughts on junior coders</summary>
      Before giving up on staffing inexperienced programmers on your team (as so many software companies seem to be doing these days),
      consider trying extra layers of specialization and junior-to-junior code reviews.
    </details>
  </div>;
}