import React from "react"

// todo: download and use webpack include
// todo: pick color theme and update:
const techColor = '9a86fd'; // $text-bright-on-solid. Common source for scss and here? (graphql or gatsby somesuch?)
const bizColor = '9a86fd'; // ffcc99 is meh $text-highlight-primary-on-solid: #ffcc99;

// star is &#9733;
function techBadgeUrl(accomplishment) {
  return `https://img.shields.io/badge/★ Tech-${accomplishment}-${techColor}.svg`;
}
function bizBadgeUrl(accomplishment) {
  return `https://img.shields.io/badge/★ Biz-${accomplishment}-${bizColor}.svg`;
}

function BizBadge({ text }) {
  return <img className="biz-badge shieldio-badge" src={bizBadgeUrl(text)} alt={text} />
}

function TechBadge({ text }) {
  return <img className="tech-badge shieldio-badge" src={techBadgeUrl(text)} alt={text} />
}

// keeping it in this file because it will be inline-included from an svg file, not used as a property
const BadgeText = {
  ExpertJs: 'Expert--level JavaScript',
  Recruiting: 'Amazing Hires',
  Initiative: 'Initiative',
  Communication: 'Communication',
  ProductPlanning: 'Product Planning',
};

// Without this, getting build error: The page component must export a React component for it to be valid
export default function wtfBuildError() {
  return <div></div>;
}

export {
  BizBadge,
  TechBadge,
  BadgeText,
}