import React from "react"
import {TwoColumnPageSection} from "../../components/PageSection";
import Card from "../../components/Card";
import CodeHighlight from "../../components/CodeHighlight";
// import emailIcon from '../../images-inline/icomoon/SVG/envelop.svg';

import './ContactSection.scss';


export default function ContactSection({ sectionNdx }) {
  // contact + availability
  return <TwoColumnPageSection
    title="in closing"
    className="contact-section"
    sectionNdx={sectionNdx}
  >
    <Card>
      <div className="subtitle">My location and email:</div>
      <CodeHighlight
        language="json"
        code={ JSON.stringify({
          "current location": 'San Francisco, but thinking to move',
          remote: '50% very nice; 100% ok'
        }, null, 2) }
      />
      <a className="button is-primary is-outlined is-fullwidth" href="mailto:jeff.ferber@gmail.com?subject=Saw your bio">
        <span className="is-hidden-print">Send Jeff an email</span>
        <span className="is-hidden is-print-visible">jeff.ferber@gmail.com</span>
      </a>

    </Card>
  </TwoColumnPageSection>;
}

