import React from "react";

import './PageSection.scss';


function TwoColumnPageSection({ title, className, sectionNdx, titleColContent, children }) {
  return <section className={ [ 'section page-section two-column-page-section', className || '' ].join(' ').trim() }>
    <div className="columns">
      <div className="column label-col">
        <h2 className="burn-bg has-text-centered">
          <span className="is-huge">{ _formatSectionNdx(sectionNdx) }</span>
          { title }
        </h2>
        { titleColContent &&
        <div className="extra-title-col-content">
          { titleColContent }
        </div>}
      </div>
      <div className="column main-column">
        { children }
      </div>
    </div>
  </section>;
}

function FullPageSection({ title, className, sectionNdx, children }) {
  return <section className={ [ 'section page-section full-page-section', className || '' ].join(' ').trim() }>
      <h2 className="burn-bg has-text-centered">
        <span className="is-huge">{ _formatSectionNdx(sectionNdx) }</span>
        { title }
      </h2>
    <div className="separator-outer">
      <div className="container">
        { children }
      </div>
    </div>
  </section>
}

function _formatSectionNdx(n) {
  if (typeof n !== 'number') {
    return '';
  }
  return n > 9 ? `${n}.` : `0${n}.`;
}

export {
  TwoColumnPageSection,
  FullPageSection,
}