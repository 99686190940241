import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import '../scss-support/prismjs-theme.scss';
import './CodeHighlight.scss';
// todo: whitespace-pre-wrap

// starting with Duotone Sea from https://github.com/PrismJS/prism-themes

export default function CodeHighlight({ code, language }) {
  const mostlyDefaultProps = Object.assign({}, defaultProps);
  delete mostlyDefaultProps.theme; // note: using their `defaultProps` overrides any css theme. Though ironically I'm setting it back to their Duo Dark default (but plan on modifying it)
  const props = Object.assign({}, mostlyDefaultProps, { code, language });



  return <Highlight
    {...props}
  >
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <pre className={`${className} code-highlight`} style={style}>
        {tokens.map((line, i) => (
          <div {...getLineProps({ line, key: i })}>
            { _parsedLineTokens(language, line, getTokenProps)}
          </div>
        ))}
      </pre>
    )}
  </Highlight>
}


function _parsedLineTokens(language, line, getTokenProps) {
  const spanForToken = (token, key) => <span {...getTokenProps({ token, key })} />;
  if (language === 'json') { // two columns
    const left = [];
    const right = [];
    let rightSplit = line.length;
    if (line.length > 2) {
      for (let i=0; i < line.length; i++) {
        const token = line[i];
        if (token && token.content === ':' && token.types[0] === 'operator') {
          rightSplit = i + 1;
          break;
        }
      }
    }
    line.forEach((token, key) => {
      (key < rightSplit ? left : right).push(spanForToken(token, key));
    });
    return <>
      <span className="line-left" key="left">{ left }</span>
      <span className="line-right" key="right">{ right }</span>
    </>;
  } else { // unchanged from example-
    return line.map(spanForToken);
  }
}