import React from "react"
import { TechBadge, BadgeText } from "./badges";
import CodeHighlight from "../../components/CodeHighlight";
import './CodeSampleSection.scss';
import {TwoColumnPageSection} from "../../components/PageSection";
import Card from "../../components/Card";
import GithubIcon from '../../images-inline/misc/github-modified.component.svg';

export default function CurrentWorkSection({ sectionNdx }) {

  return <TwoColumnPageSection
    title={ <span><span className="is-hidden-mobile">recent </span>code sample</span> }
    className="code-sample-section"
    sectionNdx={sectionNdx}
  >
    <Card
      title="@wranggle/rpc"
    >
      <TechBadge text={ BadgeText.ExpertJs } />
      <div className="code-sample-section">
        <CodeHighlight code={ JSON.stringify({
          overview: 'Help various JavaScript windows and processes interact with each other seamlessly',
          purpose: 'When authoring an automation in Wranggle Studio, up to six different types of windows / processes need to interact with each other, usually over multi-hop relays. ' +
            '@wranggle/rpc makes it easy to keep such code clean and graceful'
        }, null, 2) } language="json" />
        <a className="button is-primary is-outlined is-fullwidth" href="https://github.com/wranggle/rpc/">
          <span className="is-hidden-print"><GithubIcon className="github-icon" /> View on GitHub</span>
          <span className="is-hidden is-print-visible">github.com/wranggle/rpc</span>
        </a>
      </div>
    </Card>
  </TwoColumnPageSection>;
}