import React from "react"
import {FullPageSection } from "../../components/PageSection";
import {MiniCard} from "../../components/Card";
import umichLogo from '../../images-inline/logos/michigan-block-m.svg';
import appleLogo from '../../images-inline/logos/apple-logo-solid.svg';
import './EarlyCareerSection.scss';


export default function EarlyCareerSection({ sectionNdx }) {

  return <FullPageSection
    title="early career highlights"
    className="early-career-section"
    sectionNdx={sectionNdx}
  >
    <div className="columns is-multiline is-variable is-3 accomplishments">
      <AccomplishmentMiniCard
        iconSrc="/images/logos/eds.png"
        iconAlt="EDS"
        accomplishment="Initiative"
      >
        On my own initiative at EDS, I integrated my division's core electric utility modeling
        software with Office, permitting a breakout of task automation.
      </AccomplishmentMiniCard>

      <AccomplishmentMiniCard
        accomplishment="Product Planning"
        iconSrc={ appleLogo }
        iconAlt="Apple"
      >
        Gained a reputation for excellence at software product planning during my 4 years at Apple. Was assigned key greenfield features that successfully transitioned .Mac to a subscription service.
      </AccomplishmentMiniCard>

      <AccomplishmentMiniCard
        accomplishment="Communication"
        iconSrc={ appleLogo }
        iconAlt="Apple"
      >
        After successfully leading large launch teams as a Product Manager, I was frequently asked to help on product launches for other groups (iPhoto, iTunes, OSX.)
      </AccomplishmentMiniCard>

      <AccomplishmentMiniCard
        className="adept-holder"
        iconSrc="/images/logos/Adept_Logo.svg"
        iconAlt="Adept Technology"
        accomplishment="Communication"
      >
        When I assisted Sales with large accounts at Adept Technology, I had the highest sales-to-demo ratio of any engineer in the division.
      </AccomplishmentMiniCard>

      <AccomplishmentMiniCard
        accomplishment="Communication"
        iconSrc="/images/logos/node-summit.png"
        iconAlt="Node.js Summit"
      >
        Presented my streaming reverse web proxy on the main stage of the 2012 Node.js Summit.
      </AccomplishmentMiniCard>

      <AccomplishmentMiniCard
        accomplishment="Initiative"
        className="adept-holder"
        iconSrc="/images/logos/Adept_Logo.svg"
        iconAlt="Adept Technology"
      >
        On my own time and initiative, I wrote an algorithm for Adept's Silma-division flagship product that became a key product feature
        and a centerpiece in all subsequent product demos.
      </AccomplishmentMiniCard>

      <AccomplishmentMiniCard
        accomplishment="Education"
        className="umich-holder"
        iconSrc={ umichLogo } 
        iconAlt="University of Michigan"
      >
        University of Michigan, Ann Arbor:
        B.S.E. (cum laude) and M.B.A. (with Honors)
      </AccomplishmentMiniCard>
    </div>

  </FullPageSection>;
}

function AccomplishmentMiniCard({ accomplishment, iconSrc, iconAlt, className, children }) {
  return <div className={ [ className || '', 'column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen' ].join(' ').trim() }>
    <MiniCard
      title={ <>&#9733; {accomplishment} &#9733;</> }
      iconSrc={iconSrc}
      iconAlt={iconAlt}
    >
      { children }
    </MiniCard>
  </div>
}