import React from "react"
import {TwoColumnPageSection} from "../../components/PageSection";
import appleLogo from '../../images-inline/logos/apple-logo-solid.svg';
import ycLogo from '../../images-inline/logos/y-combinator.svg';
import './IntroSection.scss';


export default function IntroSection({ sectionNdx }) {
  return <TwoColumnPageSection
    title="tl;dr summary"
    className="intro-section"
    sectionNdx={sectionNdx}
  >
    <div className="has-text-centered">
      <div className="summary-highlights burn-bg">
        <p className="subtitle">
          2x VC-funded startup founder
        </p>
        <p className="subtitle">
          Experienced full-stack programmer
        </p>
        <p className="subtitle">
          Baseline management style: collaborative
        </p>
      </div>
      <div className="logos">
        { _summaryLogo({ imgSrc: appleLogo, text: 'Apple', className: 'apple-logo' }) }
        { _summaryLogo({ imgSrc: ycLogo, text: 'Y Combinator' }) }
        { _summaryLogo({ imgSrc: '/images/logos/michigan-go-blue.png', text: 'University of Michigan, Ann Arbor', className: 'umich-logo' }) }
        { _summaryLogo({ imgSrc: '/images/logos/michigan-ross.jpg', text: 'Michigan Ross School of Business' }) }
        { _summaryLogo({ imgSrc: '/images/logos/eds.png', text: 'EDS', className: 'eds-logo' }) }
      </div>
    </div>
  </TwoColumnPageSection>;
}

function _summaryLogo({ imgSrc, text, className }) {
  // thumb-holder
  return <div className={ [ className || '', 'summary-logo grey-img-holder-filter' ].join(' ').trim() }>
    <figure className="image is-64x64">
      <img src={imgSrc} alt={text} />
    </figure>
  </div>
}
